<template>
    <div class="grey lighten-3">
        <div class="container">

            <div class="d-flex align-items-center" style="min-height: 100vh;">
                <div class="row d-flex justify-center" style="margin: 20px 0px;">
                    <!-- col 1 -->
                    <div class="col-12 col-md-8 white d-flex align-items-center flex-column" style="padding: 35px;">
                        <v-avatar size="200">
                            <v-img :src="$store.state.StudentProfile.image" alt="Profile Picture"></v-img>
                        </v-avatar>

                        <div class="text-center py-3">
                            <h2>{{ $store.state.StudentProfile.fullname }}</h2>
                            <h4 class="font-weight-bold">{{ $store.state.StudentProfile.email }}</h4>
                            <v-btn class="m-2" :href="'tel:'+$store.state.StudentProfile.phone" color="success">Call</v-btn>
                            <v-btn class="m-2" :href="'mailto:'+$store.state.StudentProfile.email" color="red white--text">Email</v-btn>
                        </div>
                    </div>

                    <div class="col-12 col-md-8 white px-5">
                        <hr />
                    </div>

                    <!-- col 2 -->
                    <div class="col-12 col-md-8 white d-flex align-items-center justify-start" style="padding: 35px;">
                        <div class="row">
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Address</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.address }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Parent/Guardian Full Name</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.parent_fullname }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Parent/Guardian Email</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.parent_email }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Parent/Guardian Phone Number</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.parent_phone }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">What do you want to be when you grow up?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.when_grow_up }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Are you a current high school student?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.in_high_school }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">What year are you currently in</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.year_currently_in }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Graduation Year?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.graduation_year }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">What is your favorite class?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.favorite_class }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">What Level of ELL are you?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.level }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">What do you want to study after High School? Explain why?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.study_after_high_school }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">In a few words, tell us why, and how this scholarship will help you in your future academic endeavors.</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.why_scholarship }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3 grey lighten-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">The Ceremony will take place in the city of North Liberty, will you need transportation?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.ceremony }} </h4>
                            </div>
                            
                            <div class="col-12 pt-4 pb-3"
                                style="border-bottom-color: var(--bs-primary-bg-subtle); font-size: 1.3em;">
                                <h5 class="font-weight-normal">Where do you live?</h5>
                                <h4 class="font-weight-bold">: {{ $store.state.StudentProfile.where_do_you_live }} </h4>
                            </div>
                            

                            
                        </div>
                    </div>

                    <!-- <div class="col-12 text-right my-5">
                    <v-btn color="blue" class="white--text m-2">
                        <font-awesome-icon :icon="['fas', 'pen']" />
                        <span class="pl-2">Edit</span>
                    </v-btn>
                    
                    <v-btn color="red" class="white--text m-2">
                        <font-awesome-icon :icon="['fas', 'trash']" />
                        <span class="pl-2">Delete</span>
                    </v-btn>
                </div> -->
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>